import * as React from "react"

const IndexPage = () => {
  return (
    <main >
       Site Coming Soon
    </main>
  )
}

export default IndexPage
